import { useRef, useState, useEffect } from 'react';
import NftCard from '../components/nftcard';
import {fetchNFTs} from '../utils/fetchNFTs';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig, useAccount } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { Web3Button } from '@web3modal/react'

import BurnNFTs from '../components/burnwidget';
import VideoContainer from '../components/videocontainer';

const chains = [mainnet, goerli]
const projectId = 'c684dc576c7ed9d25ac0fe35831187d3'
const contract = '0x41E0b13e9CD0681033fFbd31d56a206DFecD6B7E' // mainnet
// const contract = '0xB0E0c6324C5A15A56C40c36c67DF7A08697f8C3A' // goerli

/**
 * ~~Add OrangeHare/Fansack logos~~
 * ~~change to mainnet~~
 * ~~update to look for 'not enightened' instead of 'none'~~
 * test speed diff on local files vs remote
 * ~~move buttons back~~
 * ~~pin video to top instead of buttons~~
 * ~~try to add box for burn events / errors~~
 * ~~try to forward status from transfer to said box~~
 * 
 * ~~Still need to update video component to show full mp4 when complete~~
 * BigInt issue?
 */

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

console.log(wagmiConfig);

const ethereumClient = new EthereumClient(wagmiConfig, chains)

function HomePage() {
    return <Web3Button />
} 

const Explore = () => {

    const account = useAccount();
    const [NFTs, setNFTs] = useState([])
    const contractAddress = contract
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [finalArray, setFinalArray] = useState(Array(13).fill(null));
    const [tokenIds, setTokenIds] = useState([]);

    const videoRef = useRef(null);

    const loadNFTs = () => {
        if (account) {
          fetchNFTs(account.address, contractAddress, setNFTs);
        }
    }; 

    useEffect(() => {
        loadNFTs();
    }, []);

    const refreshNFTs = () => {
        loadNFTs(); 
    };

    // useEffect(() => {
    //     if (account) {
    //         fetchNFTs(account.address, contractAddress, setNFTs)
    //     }
    // }, [account])

    useEffect(() => {
        let newFinalArray = Array(12).fill("Seeking Enlightenment");
        let newTokenIds = [];
      
        selectedTokens.forEach(token => {
          newTokenIds.push(parseInt(token.id, 16));
          token.attributes.slice(1, 13).forEach((attribute, index) => {
            if (newFinalArray[index] && newFinalArray[index] !== "Seeking Enlightenment") {
              return;
            }
            if (attribute.value !== "Seeking Enlightenment") {
              newFinalArray[index] = attribute.value;
            }
            else if (!newFinalArray[index]) {
              newFinalArray[index] = "Seeking Enlightenment";
            }
          });
        });
      
        setTokenIds(newTokenIds);
        setFinalArray(newFinalArray);
      }, [selectedTokens]);
      

    return (
        <div>
            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
            <img src="./media/hStackLogo.png" alt='' className='fixed-pos'/>
            <div className='fixed-pos'>
                <VideoContainer videoRef={videoRef} finalArray={finalArray} />
                <div className='button-container'> 
                    <div>
                        <button className="refresh-button" onClick={refreshNFTs}>
                        Refresh NFTs
                        </button>
                    </div>
                    <div className="flex justify-center">
                        <WagmiConfig config={wagmiConfig}>
                            <HomePage />
                        </WagmiConfig>
                    </div>
                    <div className="flex justify-center">
                        <WagmiConfig config={wagmiConfig}>
                            <BurnNFTs
                                tokenIds={tokenIds}
                                contractAddress={contractAddress}
                                finalArray={finalArray}
                                toAddress={account.address}
                            />
                        </WagmiConfig>
                    </div>
                </div>
            </div>
            <header className='py-24  mb-12 w-full alchemy text-center'style={{ marginTop: '20vh' }}>
            </header>
            <section className='flex flex-wrap justify-center'>
                {
                    NFTs.length > 0 ? NFTs.map(NFT => {
                       
                        return (
                           <NftCard 
                                image={NFT.media[0].gateway} 
                                id={NFT.id.tokenId } 
                                title={NFT.title} 
                                address={NFT.contract.address} 
                                description={NFT.description} 
                                attributes={NFT.metadata.attributes} 
                                selectedTokens={selectedTokens}
                                setSelectedTokens={setSelectedTokens}
                            ></NftCard>
                        )
                    }) : <div>No NFTs found</div>
                }
            </section>

            {/* </header> */}
        </div>
    )      
  
}

export default Explore
