import * as React from 'react'
import sendDevTransfer from '../utils/devTransfer'; // Add this import
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'

const BurnNFTs = ({tokenIds, finalArray, contractAddress, toAddress}) => {
  const { 
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: contractAddress,
    abi: [
      {
        "inputs": [
          {
            "internalType": "uint256[]",
            "name": "tokenIds",
            "type": "uint256[]"
          }
        ],
        "name": "batchBurn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
    ],
    functionName: 'batchBurn',
    args: [tokenIds],
  })

  const [burnError, setBurnError] = React.useState('');
  const [isTransferComplete, setIsTransferComplete] = React.useState(false); // Add this state

  const { data, error, isError, write } = useContractWrite(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  // React.useEffect(() => {
  //   if (isSuccess && !isTransferComplete && toAddress) {
  //     sendDevTransfer(toAddress)
  //       .then(() => {
  //         setIsTransferComplete(true);
  //         console.log('Transfer successful');
  //       })
  //       .catch(err => console.error('Transfer failed', err));
  //   }
  // }, [isSuccess, isTransferComplete, toAddress]); // Add this useEffect block

  React.useEffect(() => {
    if (isSuccess && !isTransferComplete && toAddress) {
      sendDevTransfer(toAddress)
        .then(() => {
          setIsTransferComplete(true);
          setPopupMessage('Transfer successful');
          setIsPopupActive(true);
        })
        .catch(err => {
          setPopupMessage('Transfer failed');
          setIsPopupActive(true);
          console.error('Transfer failed', err);
        });
    }
  }, [isSuccess, isTransferComplete, toAddress]);


  const [isPopupActive, setIsPopupActive] = React.useState(false); 
  const [popupMessage, setPopupMessage] = React.useState(''); 

  const handleBurn = () => {
    if (finalArray.includes("Seeking Enlightenment")) {
      setPopupMessage("Some still seek enlightenment");
      setIsPopupActive(true);
    } else {
      write();
    }
  }

  React.useEffect(() => {
    if (isSuccess || isPrepareError || isError || burnError) {
      setIsPopupActive(true);
    }
  }, [isSuccess, isPrepareError, isError, burnError]);

  React.useEffect(() => {
    if (isPopupActive) {
      const timer = setTimeout(() => {
        setIsPopupActive(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isPopupActive]);

  // return (
  //   <div>
  //     <button className='burn-button' disabled={!write || isLoading} onClick={handleBurn}>
  //       {isLoading ? 'Burning selected tokens...' : 'Burn'}
  //     </button>
  //     {isSuccess && (
  //       <div>
  //         Successfully burnt your NFT!
  //         <div>
  //           <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
  //         </div>
  //       </div>
  //     )}
  //     {(isPrepareError || isError || burnError) && (
  //       <div>Error: {(prepareError?.message || error?.message || burnError)}</div>
  //     )}
  //   </div>
  // );
  return (
    <div>
      <button className='burn-button' disabled={!write || isLoading} onClick={handleBurn}>
        {isLoading ? 'Burning selected tokens...' : 'Enlighten (Burn)'}
      </button>
      <div className={`message-popup ${isPopupActive ? 'active' : ''}`}>  {/* Update this line */}
        <div className="content">
          {isSuccess ? (
            <div>
              Successfully burnt your NFT!
              <div>
                <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
              </div>
            </div>
          ) : (prepareError?.message || error?.message || burnError || popupMessage)}
        </div>
      </div>
    </div>
  );
};

export default BurnNFTs;
