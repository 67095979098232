export default async function sendDevTransfer(toAddress) {
    console.log('Post Burn', toAddress);
    const url = 'https://budda-dapp-3c0e821d7a4c.herokuapp.com/devTransfer';
    // const url = 'https://google.com';

    const data = { address: toAddress };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    const response = await fetch(url, options);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}
