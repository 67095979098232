const endpoint = `https://eth-mainnet.alchemyapi.io/v2/vL_tG8sL8ZhXQpOjuWwNkh5lZHYlGDge`;
// const endpoint = `https://eth-goerli.alchemyapi.io/v2/vL_tG8sL8ZhXQpOjuWwNkh5lZHYlGDge`;

export const fetchNFTs = async (owner, contractAddress, setNFTs, retryAttempt = 0) => {

    if (retryAttempt === 5) {
        return;
    }

    if (owner) {
        let data;
        try {
            data = await fetch(`${endpoint}/getNFTs?owner=${owner}&contractAddresses%5B%5D=${contractAddress}`).then(data => data.json());
        } catch (e) {
            console.error(e);
            fetchNFTs(owner, contractAddress, setNFTs, retryAttempt + 1);
            return;
        }

        if (!data || !data.ownedNfts) {
            console.error("No data or ownedNfts returned from Alchemy API");
            return;
        }

        setNFTs(data.ownedNfts);
        return data;
    }
}


