import { useState } from 'react';
  
const CardAnimatedBorderGradient = ({ children }) => {
    return (
      <div className='relative h-full w-full rounded-md overflow-hidden'>
        <span className='absolute -top-3/4 -right-3/4 -bottom-3/4 -left-3/4 animate-[spin_8s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#ff6902_0%,#fcb4aa_50%,#b44855_100%)] z-0 rounded-full' />
        <div className='relative z-10 h-full w-full rounded-md border-4 border-transparent backdrop-blur-3xl'>
          {children}
        </div>
      </div>
    );
  };
  
const NftCard = ({ image, id, title, address, description, attributes, selectedTokens, setSelectedTokens }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelect = () => {
    setIsSelected(!isSelected);
    if (!isSelected) {
      setSelectedTokens([...selectedTokens, { image, id, title, address, description, attributes }]);
    } else {
      setSelectedTokens(selectedTokens.filter(token => token.id !== id));
    }
  };

  // const cardClasses = isSelected ? "w-1/4 mr-3 mb-4 bg-blue-500 rounded-md" : "w-1/4 mr-3 mb-4 bg-slate-100 rounded-md";
  const cardClasses = "w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 mr-3 mb-4 bg-slate-100 rounded-md";

  const cardDetails = (
    <>
      <img className='w-full rounded-t-md' key={id} src={image} alt=""></img>
      <div className="p-3">
        <div className="flex mb-3">
          <div className="flex-grow">
            <h3 className="text-xl">{title}</h3>
            <p>{`Token ID: ${parseInt(id)}`}</p>
          </div>
          <div className="flex mr-3">
            <a target="_blank" rel="noreferrer" className="text-blue-700" href={`https://etherscan.io/token/${address}`}>{`${address.slice(0, 4)}...${address.slice(address.length - 4)}`}</a>
          </div>
        </div>
        <p>{description ? description.slice(0, 200) : "No Description"}</p>
      </div>
      {/* <div className="flex flex-wrap justify-center items-center p-3 ">
        {attributes?.length > 0 && attributes.map(attribute => {
          const isEnlightened = attribute.value === 'Enlightened';
          return (
            // <div className={`w-1/2 mb-2 flex justify-start flex-col ${isEnlightened ? 'bg-yellow-200' : ''}`}>
            <div className={`w-1/2 mb-2 flex justify-start flex-col ${isEnlightened ? 'bg-yellow-200' : ''} border-2 border-gray-300 p-1 rounded-md`}>
              <p className="mr-2 font-bold">{attribute.trait_type}:</p>
              <p className="text-sm">{attribute.value}</p>
            </div>
          )
        })}
      </div> */}
      <div className="flex flex-wrap justify-center items-start p-3 ">
        {attributes?.length > 0 && attributes.map(attribute => {
          const isEnlightened = attribute.value === 'Enlightened';
          return (
            <div className={`w-1/2 mb-2 flex justify-start items-start flex-col ${isEnlightened ? 'bg-yellow-200' : ''} border-2 border-gray-300 p-1 rounded-md`}>
              <p className="mr-2 font-bold">{attribute.trait_type}:</p>
              <p className="text-sm">{attribute.value}</p>
            </div>
          )
        })}
      </div>
    </>
  );

    return (
        <div className={cardClasses} onClick={handleSelect}>
        {isSelected ? (
            <CardAnimatedBorderGradient>{cardDetails}</CardAnimatedBorderGradient>
        ) : (
            cardDetails
        )}
        </div>
    )

}

export default NftCard
