import React from 'react';

const attrMissing = (finalArray) => {
  return finalArray.includes('Seeking Enlightenment');
};

const VideoContainer = ({ videoRef, finalArray }) => {
  const isAttrMissing = attrMissing(finalArray);
  
  const videoBackground = isAttrMissing 
    ? './media/Background.mp4'
    : 'https://ipfs.io/ipfs/bafybeia2dycc2oifsmj5rpu422jzh62bbd7hy6oeh4dbzgxdwjeqile7xy/BackgroundComplete.mp4'

  const renderOverlayImages = () => {
    if (isAttrMissing) {
      return Array.from({ length: 12 }, (_, index) => {
        // const imageName = finalArray[index] !== 'None' ? `${index + 1}-green.png` : `${index + 1}-gray.png`;
        const imageName = finalArray[index] !== 'Seeking Enlightenment' ? `${index + 1}.gif` : `${index + 1}-gray.png`;
        return (
          <img
            key={index}
            className='overlay-image'
            src={`./media/${imageName}`}
            alt={`Overlay ${index + 1}`}
          />
        );
      });
    }
  };

  return (
    <div className='video-container'>
      <video
        ref={videoRef}
        src={videoBackground}
        type="video/mp4"
        playsInline
        autoPlay
        loop
        muted
        id="bgvid"
      />
      {renderOverlayImages()}
    </div>
  );
};

export default VideoContainer;
